import { Inject } from '@angular/core';
import { Component } from '@angular/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { SidebarComponent } from '@src/shared/sidebar/sidebar.component';
import { ROUTES } from './sidebar-routes.config';
import { UserAccessRoleRules } from '@src/auth/user-access-roles-rules';
import { UsersService } from '@src/services/users/users.service';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { environment } from '@src/environments/environment';
import { EnvironmentNames } from '@src/environments/environments.name';
import { ConfigService } from '@src/services/layout/config.service';

@Component({selector: 'app-sidebar-hmoser', templateUrl: '../../../shared/sidebar/sidebar.component.html'})

export class SidebarHMoserComponent extends SidebarComponent {

    constructor(accessRules: UserAccessRoleRules,
        public usersService: UsersService,
         public retailersService: RetailersService,
        @Inject('AuthService') authService : IAuthService,
        public configService: ConfigService) {
        super(accessRules, authService, usersService, retailersService, configService);

        this.RoutesInfo = ROUTES;
        this.Title = 'EWARRANTY';
        this.LogoUrl = '/assets/hmoser-icon.png';
    }
}

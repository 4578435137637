import { RouteInfo } from '@src/shared/sidebar/sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: 'user',
        position: 'top',
        accessRuleKey: 'user',
        title: 'Pages.user',
        titleFontSize: '',
        icon: 'ft-user',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: 'dashboard',
        position: 'top',
        accessRuleKey: 'dashboard',
        title: 'Pages.dashboard',
        titleFontSize: '',
        icon: 'ft-activity',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: 'sales',
        position: 'top',
        accessRuleKey: 'sales',
        title: 'Pages.sales',
        titleFontSize: '',
        icon: 'ft-search',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: 'activation',
        position: 'top',
        accessRuleKey: 'activation',
        title: 'Pages.warranty-activation',
        titleFontSize: '',
        icon: 'ft-plus-circle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    // {
    //     path: 'inventories',
    //     position: 'top',
    //     accessRuleKey: 'inventories',
    //     title: 'Pages.inventories',
    //     titleFontSize: '',
    //     icon: 'ft-file-text',
    //     class: '',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     submenu: []
    // },
    {
        path: 'retailers',
        position: 'top',
        accessRuleKey: 'retailers',
        title: 'Pages.retailers',
        titleFontSize: '',
        icon: 'icon-bag',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: 'retailer',
        position: 'top',
        accessRuleKey: 'retailer',
        title: 'Pages.retailer',
        titleFontSize: '',
        icon: 'icon-bag',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: 'users',
        position: 'top',
        accessRuleKey: 'users',
        title: 'Pages.users',
        titleFontSize: '',
        icon: 'ft-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    // {
    //     path: 'repairs',
    //     position: 'top',
    //     accessRuleKey: 'repair',
    //     title: 'Pages.repair',
    //     titleFontSize: '',
    //     icon: 'icon-wrench',
    //     class: '',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     submenu: []
    // },
    {
        path: 'management',
        position: 'top',
        accessRuleKey: 'management',
        title: 'Pages.management',
        titleFontSize: '',
        icon: 'fa fa-file-o',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    //  {
    //     path: 'customers',
    //     position: 'top',
    //     accessRuleKey: 'customers',
    //     title: 'Pages.customers',
    //     titleFontSize: '',
    //     icon: 'ft-list',
    //     class: '',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     submenu: []
    // },
    {
        path: 'privacypolicy',
        position: 'bottom',
        accessRuleKey: 'public',
        title: 'Pages.privacy-policy',
        titleFontSize: '14.25px',
        icon: 'icon-info',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: true,
        submenu: []
    }
];

import { AppSettings } from '../../core/AppSettings';
import { Injectable } from '@angular/core';

@Injectable()
export class HMoserAppSettings extends AppSettings {
    constructor() {
        super();
        // Overwrite default settings specific for H. MOSER. Example below:
        this.MaximumDaysAllowedForWarrantyReturn = 730;
        this.PrePairedWarrantyCards = false;
        this.MaximumDaysForPastActivationDate = 730;
    }
}

import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivationBaseComponent } from '@src/base-components/activation/activation.component';
import * as enums from '@src/model/Enums/productType';
import { ActivationSelectRetailerComponent } from '@src/base-components/activation/steps/select-retailer/activation-select-retailer';
import { ActivationSelectTypeComponent } from '@src/base-components/activation/steps/select-type/activation-select-type';
import { ActivationFindProductComponent } from '@src/base-components/activation/steps/find-product/activation-find-product';
import { ActivationFindWCComponent } from '@src/base-components/activation/steps/find-wc/activation-find-wc';
import { ActivationCustomerCreationComponent } from '@src/base-components/activation/steps/customer/activation-customer-creation';
import { UserRolesEnum } from '@src/model/userRoles';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})
export class ActivationComponent extends ActivationBaseComponent {

  @ViewChild(ActivationSelectRetailerComponent) activationSelectRetailerComponent: ActivationSelectRetailerComponent;
  @ViewChild(ActivationSelectTypeComponent) activationSelectTypeComponent: ActivationSelectTypeComponent;
  @ViewChild(ActivationFindProductComponent) activationFindProductComponent: ActivationFindProductComponent;
  @ViewChild(ActivationFindWCComponent) activationFindWCComponent: ActivationFindWCComponent;
  @ViewChild(ActivationCustomerCreationComponent) activationCustomerCreation: ActivationCustomerCreationComponent;

  ProductType = enums.ProductType;

  ngOnInit() {
    this.activationFindProductComponent.canSelectFakeSN = false;
    this.activationFindProductComponent.canDoDuplication = false;
  }

  resetToStep0() {
    this.activationSelectRetailerComponent.reset();
    this.resetToStep1();
  }

  resetToStep1() {
    this.activationFindProductComponent.reset();
    this.resetToStep2();
  }

  resetToStep2() {
    this.activationFindWCComponent.reset();
    this.resetToStep3();
  }

  resetToStep3() {
    if (this.activationCustomerCreation == undefined) {
      this.validationErrors = [];
    }
  }

  resetToStep4() {
    this.validationErrors = [];
  }
}

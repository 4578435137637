import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { HMoserModule } from './hmoser.module';
import { environment } from '@src/environments/environment';
import { EnvironmentNames } from '@src/environments/environments.name';

if (environment.envName === EnvironmentNames.prod) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(HMoserModule)
  .catch(err => console.log(err));
